import { useContext } from 'react';
import { TimeoutAlertContext } from 'contexts/TimeoutAlertContext';

export const useTimeoutAlert = () => {
  const {
    transactionOccurring,
    displayContacting,
    displayUnavailable,
    setTransactionOccurring,
    setDisplayContacting,
    setDisplayUnavailable,
  } = useContext(TimeoutAlertContext) || {};

  return {
    transactionOccurring,
    displayContacting,
    displayUnavailable,
    setTransactionOccurring,
    setDisplayContacting,
    setDisplayUnavailable,
  };
};

export default useTimeoutAlert;

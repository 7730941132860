import { useUser, useStore } from 'hooks';
import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { getProtectedConfig } from 'apis/config';

export const AuthedRoute = () => {
  const { user } = useUser();
  const { setStore } = useStore();

  useEffect(() => {
    if (user?.authenticated !== true) {
      return;
    }
    getProtectedConfig().then(protectedConfig => {
      if (!protectedConfig) {
        throw new Error('Empty configuration object returned from API');
      }
      setStore(s => ({
        ...s,
        config: { ...s.config, ...protectedConfig },
      }));
    });
  }, [user, setStore]);

  if (user?.authenticated !== true) {
    return <Navigate to={'/login'} />;
  }

  return <Outlet />;
};

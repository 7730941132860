export const fetchBackend = async (url, fetchOptions) => {
  console.log('Fetching from backend', { url, fetchOptions });
  const resp = await fetch(url, fetchOptions);

  if (resp.status === 401) {
    window.location.href = '/login';
    return;
  }

  return resp;
};

import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { getPublicConfig, getConfigAsset } from 'apis/config';
import { useConfig, useStore } from 'hooks';

const ErrorContainer = styled.div`
  text-align: center;
  color: #ff4d4f;
`;

export const WaitForConfig = () => {
  const { store, setStore } = useStore();
  const [error, setError] = useState();
  const { setOptions } = useConfig();

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      if (ignore) return;
      getPublicConfig()
        .then(async publicConfig => {
          if (ignore) return;
          if (!publicConfig) {
            throw new Error('Empty configuration object returned from API');
          }

          let { titleFav, faviconUrl, logoUrl } = publicConfig;

          const promises = [];

          if (faviconUrl && faviconUrl[0] === '/') {
            promises.push(
              getConfigAsset(faviconUrl)
                .then(imageBlob => {
                  faviconUrl = URL.createObjectURL(imageBlob);
                })
                .catch(err => {
                  faviconUrl = null;
                  console.error(err);
                }),
            );
          }

          if (logoUrl && logoUrl[0] === '/') {
            promises.push(
              getConfigAsset(logoUrl)
                .then(imageBlob => {
                  logoUrl = URL.createObjectURL(imageBlob);
                })
                .catch(err => {
                  logoUrl = null;
                  console.error(err);
                }),
            );
          }

          if (promises.length > 0) await Promise.all(promises);
          if (ignore) return;

          if (titleFav) {
            document.title = titleFav ?? '4V Nexus';
          }

          if (faviconUrl) {
            const element = document.querySelector("link[rel~='icon']");
            element.href = faviconUrl;
          }

          setOptions(publicConfig);
          setStore(s => ({
            ...s,
            config: {
              ...s.config,
              ...publicConfig,
              logoUrl,
              faviconUrl,
            },
            publicConfigLoaded: true,
          }));
        })
        .catch(err => {
          setError(
            err?.toString?.() ||
              'Something went wrong while fetching configuration from API',
          );
        });
    };

    fetchData();

    // Refresh app config every 10 minutes
    const intervalId = setInterval(() => {
      fetchData();
    }, 600000);

    return () => {
      ignore = true;
      clearInterval(intervalId);
    };
  }, [setOptions, setStore]);

  if (error) {
    return <ErrorContainer>{error}</ErrorContainer>;
  }

  if (!store.publicConfigLoaded) {
    return;
  }

  return <Outlet />;
};

import { DialogProvider } from 'contexts/DialogContext';
import { StoreProvider } from 'contexts/StoreContext';
import { UserProvider } from 'contexts/UserContext';
import { ConfigProvider } from 'contexts/ConfigContext';
import { MatomoInstance } from 'matomo/matomo.config';
import { TimeoutAlertProvider } from 'contexts/TimeoutAlertContext';
import { AntDesignProvider, NexusTheme } from 'contexts/AntDesignContext';
import { lazy, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AuthedRoute, UnAuthedRoute, WaitForConfig } from 'routes';

const Login = lazy(() => import('pages/LoginPage'));
const SearchPage = lazy(() => import('pages/SearchPage'));
const RepositoryPage = lazy(() => import('pages/RepositoryPage'));
const ContentListPage = lazy(() => import('pages/ContentList'));
const DocumentManagementPage = lazy(() =>
  import('pages/DocumentManagementPage'),
);
const IndividualUrlListPage = lazy(() => import('pages/IndividualUrlList'));
const CredentialsListPage = lazy(() => import('pages/CredentialsList'));
const OntologyPage = lazy(() => import('pages/OntologyPage'));
const AppManagementPage = lazy(() => import('pages/AppManagementPage'));

function App() {
  return (
    <StoreProvider>
      <UserProvider>
        <ConfigProvider>
          <AntDesignProvider theme={NexusTheme}>
            <MatomoInstance>
              <TimeoutAlertProvider>
                <DialogProvider>
                  <Suspense fallback={<div>...</div>}>
                    <Routes>
                      {/* Layout route to wait for config API before rendering any route */}
                      <Route element={<WaitForConfig />}>
                        {/* Authed routes */}
                        <Route path="/" element={<AuthedRoute />}>
                          <Route path="" element={<SearchPage />} />
                          <Route
                            path="repository"
                            element={<RepositoryPage />}
                          />
                          <Route
                            path="content/list"
                            element={<ContentListPage />}
                          />
                          <Route
                            path="document-management/list"
                            element={<DocumentManagementPage />}
                          />
                          <Route
                            path="individual-url/list"
                            element={<IndividualUrlListPage />}
                          />
                          <Route
                            path="credentials/list"
                            element={<CredentialsListPage />}
                          />

                          <Route path="ontology" element={<OntologyPage />} />
                          <Route
                            path="application-management/list"
                            element={<AppManagementPage />}
                          />
                        </Route>
                        {/* UnAuthed routes */}
                        <Route path="/" element={<UnAuthedRoute />}>
                          <Route path="login" element={<Login />} />
                        </Route>
                        <Route path="*" element={<Navigate to="/" />} />
                      </Route>
                    </Routes>
                  </Suspense>
                </DialogProvider>
              </TimeoutAlertProvider>
            </MatomoInstance>
          </AntDesignProvider>
        </ConfigProvider>
      </UserProvider>
    </StoreProvider>
  );
}

export default App;

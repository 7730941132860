import { useUser } from 'hooks';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const UnAuthedRoute = () => {
  const { user, previousUrl } = useUser();

  if (user?.authenticated === true) {
    if (previousUrl) {
      window.location.href = previousUrl;
      return;
    }
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getPublicConfig, updatePublicConfig } from 'apis/config';
import { useStore, useUser } from 'hooks';

const notSetText = 'Not set';

const ConfigContext = createContext();

const ConfigProvider = ({ children }) => {
  const { store } = useStore();
  const { user } = useUser();
  // DMC: I regret making configOptions an array. I think it might have been simpler to have an object with properties
  // using the 'property' value as the name. That would get rid of the need to call configOptions.find to look for a
  // particular value. That same argument might apply for the settings array.

  // DLO: This is what configOptions *should* look like to allow hooks' dependencies to properly track property changes.
  //   Would recommend also Object-ifying the "settings" property if necessary, but I also understand the settings properties
  //   are meant to reflect how they're rendered in the UI.
  const [configOptions, setConfigOptions] = useState(
    {
      announcementBanner: {
        type: 'group',
        label: 'Announcement Banner',
        settings: {
          banner: {
            label: 'Text',
            type: 'textArea',
            value: null,
            rules: [
              {
                max: 200,
                message: `Maximum length is 200 characters`,
              },
            ],
          },
        },
      },
      externalPage: {
        type: 'group',
        label: 'External Page',
        settings: {
          title: {
            label: 'Title',
            type: 'text',
            value: null,
            rules: [
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const url = getFieldValue('url');
                  // If both fields are empty then this field is valid
                  // If both fields are filled then this field is valid
                  if ((!value && !url) || (value && url)) {
                    return Promise.resolve();
                  } else if (!value && url) {
                    // If the other is filled but this field is missing then this field is valid
                    return Promise.reject(
                      new Error(
                        `Please input a title (clear both fields to disable)`,
                      ),
                    );
                  } else {
                    // In the case where this field is filled, but the other is missing we let the other field deliver the error message
                    return Promise.reject(new Error(``));
                  }
                },
              }),
            ],
          },
          url: {
            label: 'URL',
            type: 'text',
            value: null,
            rules: [
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const title = getFieldValue('title');
                  // If both fields are empty then this field is valid
                  // If both fields are filled then this field is valid
                  if ((!value && !title) || (value && title)) {
                    return Promise.resolve();
                  } else if (!value && title) {
                    // If the other is filled but this field is missing then this field is valid
                    return Promise.reject(
                      new Error(
                        `Please input a URL (clear both fields to disable)`,
                      ),
                    );
                    // In the case where this field is filled, but the other is missing we let the other field deliver the error message
                  } else {
                    return Promise.reject(new Error(``));
                  }
                },
              }),
            ],
          },
        },
      },
      feedback: {
        type: 'group',
        label: 'Feedback',
        settings: {
          email: {
            label: 'Email Address',
            type: 'text',
            value: '4VNexusFeedback@4VServices.com',
          },
        },
      },
      saml: {
        disabled: true,
        type: 'group',
        label: 'SAML Username Source',
        settings: {
          usernameSource: {
            label: 'SAML Username Attribute',
            type: 'text',
            value: null,
          },
        },
      },
    },
    // Next up Matomo settings. Need to handle different types of values (boolean, select). Add the value property.
    // matomo: {
    //   type: 'group',
    //   label: 'Matomo',
    //   settings: {
    //     enabled: { label: 'Enabled', type: 'boolean' },
    //     matomoUrlBase: {
    //       label: 'URL Base',
    //       type: 'text',
    //     },
    //     protocol: {
    //       label: 'Protocol',
    //       type: 'select',
    //       options: ['http', 'https'],
    //     },
    //     matomoTrackerUrl: {
    //       label: 'Tracker URL',
    //       type: 'text',
    //     },
    //     matomoSrcUrl: {
    //       label: 'Source URL',
    //       type: 'text',
    //     },
    //   },
    //   displayText: useCallback(() => {
    //     const config = store.config?.matomo;
    //     if (config && config.enabled) {
    //       return 'Enabled';
    //     } else {
    //       return 'Disabled';
    //     }
    //   }),
    // }
  );

  useEffect(() => {
    if (store?.config?.useSamlAuth && user?.attributes) {
      setConfigOptions(currentOptions => {
        const newOptions = { ...currentOptions };
        newOptions.saml.disabled = false;
        newOptions.saml.settings.usernameSource.rules = [
          {
            type: 'enum',
            enum: [...Object.keys(user.attributes), ''], // The empty string here is to allow the user to submit an empty field in order to unset this config
            message:
              'Error: The specified attribute could not be read from the SAML token. Please verify that you have typed the attribute name correctly. Check with your IdP provider to confirm the name and availability of the desired attribute.',
          },
        ];
        return newOptions;
      });
    }
  }, [store?.config?.useSamlAuth, user?.attributes]);

  // Summarize the banner fields for the table in AppConfigOptionsList (or anywhere else we need a summary).
  const displayBannerSummary = useCallback(() => {
    return (
      configOptions.announcementBanner?.settings?.banner?.value || notSetText
    );
  }, [configOptions.announcementBanner.settings.banner.value]);

  // Summarize the external page fields for the table in AppConfigOptionsList
  const displayExternalPage = useCallback(() => {
    const externalPageTitle =
      configOptions.externalPage?.settings?.title?.value;
    const externalPageUrl = configOptions.externalPage?.settings?.url?.value;

    if (!externalPageTitle && !externalPageUrl) {
      return notSetText;
    }

    return `${externalPageTitle}: ${externalPageUrl}`;
  }, [
    configOptions.externalPage.settings.title.value,
    configOptions.externalPage.settings.url.value,
  ]);

  // Summarize the feedback fields for the table in AppConfigOptionsList
  const displayFeedback = useCallback(() => {
    return configOptions.feedback?.settings?.email?.value || notSetText;
  }, [configOptions.feedback.settings.email.value]);

  const displaySaml = useCallback(() => {
    return configOptions.saml?.settings?.usernameSource?.value || notSetText;
  }, [configOptions.saml.settings.usernameSource.value]);

  // We're setting up these displayText callbacks here because they are part of the configOptions, but need to refer to
  // values in the configOptions. There may be a better solution.
  useEffect(() => {
    setConfigOptions(options => {
      options.announcementBanner.displayText = displayBannerSummary;
      options.feedback.displayText = displayFeedback;
      options.externalPage.displayText = displayExternalPage;
      options.saml.displayText = displaySaml;
      return options;
    });
  }, [
    setConfigOptions,
    displayBannerSummary,
    displayExternalPage,
    displayFeedback,
    displaySaml,
  ]);

  /**
   * Use setOptions to record option updates during initialization. This will set the Config values, but not send
   * updates to the server.
   * @param {*} options
   */
  const setOptions = useCallback(
    options => {
      setConfigOptions(currentOptions => {
        const newOptions = { ...currentOptions };
        for (const [optionName, optionValue] of Object.entries(options)) {
          for (const [settingName, settingValue] of Object.entries(
            optionValue,
          )) {
            if (newOptions[optionName]?.settings?.[settingName]) {
              newOptions[optionName].settings[settingName].value = settingValue;
            }
          }
        }
        return newOptions;
      });
    },
    [setConfigOptions],
  );

  const updateOptions = useCallback(async () => {
    const mlAppSettings = {};
    for (const [optionName, optionValues] of Object.entries(configOptions)) {
      for (const [settingName, settingValues] of Object.entries(
        optionValues.settings,
      )) {
        if (!mlAppSettings[optionName]) mlAppSettings[optionName] = {};
        mlAppSettings[optionName][settingName] = settingValues.value;
      }
    }
    await updatePublicConfig(mlAppSettings);
    await getPublicConfig({ forceRefresh: true });
  }, [configOptions]);

  const value = useMemo(
    () => ({
      configOptions,
      setOptions,
      updateOptions,
    }),
    [configOptions, setOptions, updateOptions],
  );

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export { ConfigContext, ConfigProvider };

import { fetchBackend } from 'utils/apiUtils';

export const login = async ({ username, password }) => {
  return fetch(`/api/auth/login/marklogic`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  });
};

export const getCurrentUser = async () => {
  return fetchBackend(`/api/users/me`, {})
    .then(resp => {
      if (resp.ok) return resp.json();
      throw new Error('');
    })
    .catch(err => {
      return null;
    });
};

export const logout = async () => {
  return fetchBackend(`/api/auth/logout`, {
    method: 'POST',
  });
};
import { useContext, useMemo } from 'react';
import { ConfigContext } from 'contexts/ConfigContext';

export const useConfig = () => {
  const { configOptions, setOptions, updateOptions } =
    useContext(ConfigContext) || {};

  const externalPage = useMemo(() => {
    const settings = {
      title: configOptions?.externalPage?.settings?.title?.value,
      url: configOptions?.externalPage?.settings?.url?.value,
    };

    if (settings.url && !/^https?:\/\//.test(settings.url))
      settings.url = `https://${settings.url}`;

    return settings;
  }, [
    configOptions.externalPage.settings.title.value,
    configOptions.externalPage.settings.url.value,
  ]);

  return {
    configOptions,
    externalPage,
    setOptions, // Set all options based on what we get from the server or from user input
    updateOptions, // Update options object stored in Marklogic
  };
};

export default useConfig;
